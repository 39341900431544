.appShell {
  --sidenav-cut: 2.5rem;
  --main-cut: 0.25rem;
}

.header {
  border-bottom: none;
  background-color: #fcfcfc;
  inset-inline-start: calc(
    var(--app-shell-navbar-offset, 0rem) +
      var(--app-shell-padding) - var(--sidenav-cut) - var(--main-cut)
  );
}

.main {
  background-color: #fcfcfc;
  height: 100vh;
  padding-top: calc(var(--app-shell-header-offset, 0rem) + 15px);

  @media (min-width: 48em) {
    padding-inline-start: calc(
      var(--app-shell-navbar-offset, 0rem) +
        var(--app-shell-padding) - var(--sidenav-cut) - var(--main-cut)
    );
    padding-inline-end: calc(
      var(--app-shell-aside-offset, 0rem) +
        var(--app-shell-padding) - var(--main-cut)
    );
  }
}

.navbar {
  width: calc(var(--app-shell-navbar-width) - var(--sidenav-cut));
}
