.root {
  padding-top: 80px;
  padding-bottom: 80px;
}

.title {
  text-align: center;
  font-weight: 900;
  font-size: 4rem;
  line-height: 1;
  margin-bottom: calc(1.5 * var(--mantine-spacing-xl));
  color: var(--mantine-color-gray-2);

  @media (max-width: 48em) {
    font-size: 32px;
  }
}

.description {
  max-width: 500px;
  margin: auto;
  margin-top: var(--mantine-spacing-xl);
  margin-bottom: calc(1.5 * var(--mantine-spacing-xl));
}
