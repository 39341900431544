.item {
  padding: var(--mantine-spacing-sm) 0;

  @media (min-width: 48em) {
    min-width: 19rem;
    max-width: 100%;
    border-block: 1px solid transparent;
  }

  &.itemActive {
    background-color: rgb(245 246 247 / var(--tw-bg-opacity, 1));
    border-block: 1px solid var(--border-color);
  }
}
