.card {
  background-color: var(--mantine-color-body);
}

.item {
  padding: var(--mantine-spacing-sm) 0;

  &.itemActive {
    background-color: var(--mantine-primary-color-light);
  }
}

.switch {
  & * {
    cursor: pointer;
  }
}

.title {
  line-height: 1;
}
