.navbarContainer {
  background-color: light-dark(
    var(--mantine-color-white),
    var(--mantine-color-dark-6)
  );
  height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.header {
  padding: var(--mantine-spacing-md);
  max-height: 60px;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
}

.logo {
  display: flex;
  padding-top: 0.5rem;

  svg {
    max-width: 80px;
  }
}

.links {
  flex: 1;
  padding-top: 1rem;
}

.linksInner {
  padding: var(--mantine-spacing-xs) 0;
}

.footer {
  display: flex;
  gap: 1rem;
  padding: var(--mantine-spacing-md);
  justify-content: center;
  flex-direction: column;
}
