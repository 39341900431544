.list {
  gap: 2rem;
  flex-wrap: nowrap;
  position: relative;
  overflow-y: scroll;
  margin-bottom: calc(var(--mantine-spacing-lg) + 0.5rem) !important;
}

.indicator {
  background-color: var(--mantine-color-white);
  border-bottom: 1px solid var(--mantine-color-dark-4);

  @mixin dark {
    background-color: var(--mantine-color-dark-6);
  }
}

.tab {
  z-index: 1;
  font-weight: 600;
  padding: 0.65rem 0;
  transition: color 100ms ease;
  color: var(--mantine-color-gray-7);

  &[data-active] {
    color: var(--mantine-color-black);
  }

  @mixin dark {
    color: var(--mantine-color-dark-1);

    &[data-active] {
      color: var(--mantine-color-white);
    }
  }
}

.panel {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 10rem);

  @media screen and (min-width: 62em) {
    overflow-y: unset;
    overflow-x: unset;
    height: 100%;
  }
}
