.leftPlan {
  border-radius: 0.75rem;
  border: 1px solid var(--input-border-color);
  padding: 1.5rem 1.25rem;
}

.rightPlan {
  border-radius: 0.75rem;
  border: 1px solid var(--input-border-color);
  padding: 1.5rem 1.25rem;
  background-color: #fcfcfc;
}

@media (min-width: 75em) {
  .leftPlan {
    border-right: none;
    padding: 2rem 2.5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rightPlan {
    padding: 2rem 2.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
