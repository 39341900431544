.voiceOption {
  padding: 0;

  &:where([data-combobox-disabled]) {
    cursor: pointer;
    opacity: 0.7;
  }

  &:hover {
    background-color: var(--mantine-color-gray-1);
  }
}

.voiceOptionItem {
  &.voiceOptionItemClicked {
    background-color: var(--mantine-color-gray-1);
  }
}
