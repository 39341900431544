.dropzone {
  width: 160px;
  height: 160px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.avatar {
  padding: 10px;
  border: 1px dashed var(--mantine-color-gray-2);
}
