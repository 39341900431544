@mixin hover {
  &:hover {
    &:not(.controlActive):not(.controlOpenActive) {
      background-color: light-dark(
        var(--mantine-color-gray-0),
        var(--mantine-color-dark-7)
      );
      color: light-dark(
        var(--mantine-color-black),
        var(--mantine-color-dark-0)
      );
    }
  }
}

.control {
  display: block;
  border-radius: var(--mantine-radius-md);
  width: calc(100% - var(--mantine-spacing-md) * 2);
  margin: calc(var(--mantine-spacing-sm) + 0.25rem) var(--mantine-spacing-md);
  padding: 10px var(--mantine-spacing-md);
  font-size: 0.875rem;
  border: 1px solid transparent;

  @include hover;

  &.controlActive {
    background-color: var(--hover-bg);
    font-weight: 500;
    border-color: var(--nl-color);
  }

  &.controlOpenActive {
    background-color: light-dark(
      var(--mantine-color-gray-0),
      var(--mantine-color-dark-7)
    );
  }
}

.link {
  font-weight: 500;
  display: block;
  text-decoration: none;
  border-radius: 0 var(--mantine-radius-md) var(--mantine-radius-md) 0;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  padding-left: calc(var(--mantine-spacing-md) + 0.34rem);
  font-size: 0.875rem;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));

  @include hover;
}

.chevron {
  transition: transform 200ms ease;
}

.items {
  margin: 0 var(--mantine-spacing-md) 0 2.6rem;
  border-left: 1px solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.linkIcon {
  margin-right: var(--mantine-spacing-sm);
  color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-2));
}
