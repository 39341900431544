:root {
  --primary-color: #5e6ab2;
  --hover-bg: #f3f3f9;
  --text-color: #1a1a1a;
  --bg-color: #fcfcfc;
  --border-color: #e1e1e2;
  --border-blue: #bae0ff;
  --green-color: #149041;
  --red-color: #f4651f;
  --error-color: #d1312c;
  --input-border-color: #e5e7eb;
  --input-asterisk-color: currentColor;
  --mantine-color-error: var(--mantine-color-red-6);
}

html,
body {
  @media screen and (min-width: 48em) {
    overflow: hidden;
  }
}

.custom-text-color {
  color: var(--text-color);
}

.custom-bg-color {
  background-color: var(--bg-color);
}

.custom-border-color {
  border-color: var(--border-color);
}

.custom-hover-border-color {
  border-color: var(--primary-color);
}

.btn-primary {
  background-color: var(--primary-color);
  height: 44px;
  border-radius: 6px;
  padding: 0 16px;
  color: white;
  transition: all 0.2s ease-in-out;
}

.btn-primary:hover {
  opacity: 0.9;
}

.custom-green-color {
  color: var(--green-color);
}

.custom-red-color {
  color: var(--red-color);
}

.custom-box-shadow {
  box-shadow:
    0px 6px 16px 0px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
}

@media screen and (max-height: 690px) {
  .signup-container {
    /* background-color: green; */
    display: block;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media screen and (min-height: 690px) {
  .signup-container {
    height: calc(100vh - 70px);
    /* background-color: red; */
    display: flex;
  }
  .signup-container.extend {
    height: calc(100vh - 120px);
  }
}
