@mixin hover {
  &:hover {
    background-color: light-dark(
      var(--mantine-color-gray-0),
      var(--mantine-color-dark-8)
    );
  }
}

.user {
  display: block;
  width: 100%;
  padding: var(--mantine-spacing-md);
  color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));

  @include hover;
}

.userButton {
  padding: 0.2rem 0;
  max-width: 100%;
}
