.user {
  display: block;
  width: 100%;
  padding: var(--mantine-spacing-sm);
  color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  border-radius: var(--mantine-radius-md);
  transition: background-color 100ms ease;
  background-color: light-dark(
    var(--mantine-color-gray-0),
    var(--mantine-color-dark-8)
  );

  @media (min-width: 48em) {
    width: auto;
  }
}

.userActive {
  background-color: var(--mantine-color-gray-1);
}

.menuItem {
  padding: calc(var(--mantine-spacing-sm) / 1.5) var(--mantine-spacing-sm);

  &:hover {
    border-radius: 0;
  }
}
